import axios from "axios";
import authHeader from "./auth-header";
import AuthService from "./auth.service";
import { config } from "../config";

class Proposal {
  getPriorityList() {
    return axios
      .get(`${config["apiUrl"]}proposal/priority`, {
        headers: authHeader(),
      })
      .then((response) => {
        if (response.status == 200) {
          return response.data;
        }
        return null;
      })
      .catch((error) => {
        if (error.response.status == 401) {
          AuthService.logout();
          window.location = "/login";
        }
        console.log("ERROR:: ", error.response.data);
        return null;
      });
  }
  getTypesList() {
    return axios
      .get(`${config["apiUrl"]}proposal/types`, {
        headers: authHeader(),
      })
      .then((response) => {
        if (response.status == 200) {
          return response.data;
        }
        return null;
      })
      .catch((error) => {
        if (error.response.status == 401) {
          AuthService.logout();
          window.location = "/login";
        }
        console.log("ERROR:: ", error.response.data);
        return null;
      });
  }
  getCategoryList() {
    return axios
      .get(`${config["apiUrl"]}proposal/categories`, {
        headers: authHeader(),
      })
      .then((response) => {
        if (response.status == 200) {
          return response.data;
        }
        return null;
      })
      .catch((error) => {
        if (error.response.status == 401) {
          AuthService.logout();
          window.location = "/login";
        }
        console.log("ERROR:: ", error.response.data);
        return null;
      });
  }
  getStatusList() {
    return axios
      .get(`${config["apiUrl"]}proposal/status`, {
        headers: authHeader(),
      })
      .then((response) => {
        if (response.status == 200) {
          return response.data;
        }
        return null;
      })
      .catch((error) => {
        if (error.response.status == 401) {
          AuthService.logout();
          window.location = "/login";
        }
        console.log("ERROR:: ", error.response.data);
        return null;
      });
  }
  getByStatus(status) {
    return axios
      .get(`${config["apiUrl"]}proposal/status/${status}`, {
        headers: authHeader(),
      })
      .then((response) => {
        if (response.status == 200) {
          return response.data;
        }
        return null;
      })
      .catch((error) => {
        if (error.response.status == 401) {
          AuthService.logout();
          window.location = "/login";
        }
        console.log("ERROR:: ", error.response.data);
        return null;
      });
  }
  countByStatus(status) {
    return axios
      .get(`${config["apiUrl"]}proposal/status/${status}/count`, {
        headers: authHeader(),
      })
      .then((response) => {
        if (response.status == 200) {
          return response.data;
        }
        return null;
      })
      .catch((error) => {
        if (error.response.status == 401) {
          AuthService.logout();
          window.location = "/login";
        }
        console.log("ERROR:: ", error.response.data);
        return null;
      });
  }

  setAssigned(proposalId, $bodyParms) {
    return axios
      .patch(`${config["apiUrl"]}proposal/${proposalId}/assigned`, $bodyParms, {
        headers: authHeader(),
      })
      .then((response) => {
        if (response.status != undefined && response.status == 200) {
          return response.data;
        }
        return null;
      })
      .catch((error) => {
        if (error.response.status == 401) {
          AuthService.logout();
          window.location = "/login";
        }
        console.log("ERROR:: ", error.response.data);
        return null;
      });
  }

  setCategory(proposalId, $bodyParms) {
    return axios
      .patch(`${config["apiUrl"]}proposal/${proposalId}/category`, $bodyParms, {
        headers: authHeader(),
      })
      .then((response) => {
        if (response.status != undefined && response.status == 200) {
          return response.data;
        }
        return null;
      })
      .catch((error) => {
        if (error.response.status == 401) {
          AuthService.logout();
          window.location = "/login";
        }
        console.log("ERROR:: ", error.response.data);
        return null;
      });
  }

  setPriority(proposalId, $bodyParms) {
    return axios
      .patch(`${config["apiUrl"]}proposal/${proposalId}/priority`, $bodyParms, {
        headers: authHeader(),
      })
      .then((response) => {
        if (response.status != undefined && response.status == 200) {
          return response.data;
        }
        return null;
      })
      .catch((error) => {
        if (error.response.status == 401) {
          AuthService.logout();
          window.location = "/login";
        }
        console.log("ERROR:: ", error.response.data);
        return null;
      });
  }
  setStatus(proposalId, $bodyParms) {
    return axios
      .patch(`${config["apiUrl"]}proposal/${proposalId}/status`, $bodyParms, {
        headers: authHeader(),
      })
      .then((response) => {
        if (response.status != undefined && response.status == 200) {
          return response.data;
        }
        return null;
      })
      .catch((error) => {
        if (error.response.status == 401) {
          AuthService.logout();
          window.location = "/login";
        }
        console.log("ERROR:: ", error.response.data);
        return null;
      });
  }
  getProposalInfo(proposalId) {
    return axios
      .get(`${config["apiUrl"]}proposal/${proposalId}`, {
        headers: authHeader(),
      })
      .then((response) => {
        if (response.status == 200) {
          return response.data;
        }
        return null;
      })
      .catch((error) => {
        if (error.response.status == 401) {
          AuthService.logout();
          window.location = "/login";
        }
        console.log("ERROR:: ", error.response.data);
        return null;
      });
  }

  sendMessage(proposalId, formData) {
    let headers = authHeader();
    headers["Content-Type"] = "multipart/form-data";
    return axios
      .post(`${config["apiUrl"]}proposal/${proposalId}/message`, formData, {
        headers: headers,
      })
      .then((response) => {
        if (response.status == 200) {
          return response.data;
        }
        return null;
      })
      .catch((error) => {
        if (error.response.status == 401) {
          AuthService.logout();
          window.location = "/login";
        }
        console.log("ERROR:: ", error.response.data);
        return null;
      });
  }

  update(propolsalId, $bodyParms) {
    return axios
      .put(`${config["apiUrl"]}proposal/${propolsalId}`, $bodyParms, {
        headers: authHeader(),
      })
      .then((response) => {
        if (response.status != undefined && response.status == 200) {
          return response.data;
        }
        return null;
      })
      .catch((error) => {
        if (error.response.status == 401) {
          AuthService.logout();
          window.location = "/login";
        }
        console.log("ERROR:: ", error.response.data);
        return null;
      });
  }

  requestSurvey(propolsalId) {
    return axios
      .post(
        `${config["apiUrl"]}proposal/${propolsalId}/requestSurvey`,
        {},
        {
          headers: authHeader(),
        }
      )
      .then((response) => {
        if (response.status != undefined && response.status == 200) {
          return response.data;
        }
        return null;
      })
      .catch((error) => {
        if (error.response.status == 401) {
          AuthService.logout();
          window.location = "/login";
        }
        console.log("ERROR:: ", error.response.data);
        return null;
      });
  }

  countUnbudgeted() {
    return axios
      .get(`${config["apiUrl"]}proposal/unbudgeted/count`, {
        headers: authHeader(),
      })
      .then((response) => {
        if (response.status == 200) {
          return response.data;
        }
        return null;
      })
      .catch((error) => {
        if (error.response.status == 401) {
          AuthService.logout();
          window.location = "/login";
        }
        console.log("ERROR:: ", error.response.data);
        return null;
      });
  }

  getUnbudgeted() {
    return axios
      .get(`${config["apiUrl"]}proposal/unbudgeted`, {
        headers: authHeader(),
      })
      .then((response) => {
        if (response.status == 200) {
          return response.data;
        }
        return null;
      })
      .catch((error) => {
        if (error.response.status == 401) {
          AuthService.logout();
          window.location = "/login";
        }
        console.log("ERROR:: ", error.response.data);
        return null;
      });
  }

  toggleBudget(proposalId) {
    return axios
      .patch(
        `${config["apiUrl"]}proposal/${proposalId}/toggleBudget`,
        {},
        {
          headers: authHeader(),
        }
      )
      .then((response) => {
        if (response.status != undefined && response.status == 200) {
          return response.data;
        }
        return null;
      })
      .catch((error) => {
        if (error.response.status == 401) {
          AuthService.logout();
          window.location = "/login";
        }
        console.log("ERROR:: ", error.response.data);
        return null;
      });
  }

  countWithoutClassification() {
    return axios
      .get(`${config["apiUrl"]}proposal/withoutClassification/count`, {
        headers: authHeader(),
      })
      .then((response) => {
        if (response.status == 200) {
          return response.data;
        }
        return null;
      })
      .catch((error) => {
        if (error.response.status == 401) {
          AuthService.logout();
          window.location = "/login";
        }
        console.log("ERROR:: ", error.response.data);
        return null;
      });
  }

  getWithoutClassification() {
    return axios
      .get(`${config["apiUrl"]}proposal/withoutClassification`, {
        headers: authHeader(),
      })
      .then((response) => {
        if (response.status == 200) {
          return response.data;
        }
        return null;
      })
      .catch((error) => {
        if (error.response.status == 401) {
          AuthService.logout();
          window.location = "/login";
        }
        console.log("ERROR:: ", error.response.data);
        return null;
      });
  }
  getOpenAndClassified() {
    return axios
      .get(`${config["apiUrl"]}proposal/openAndClassified`, {
        headers: authHeader(),
      })
      .then((response) => {
        if (response.status == 200) {
          return response.data;
        }
        return null;
      })
      .catch((error) => {
        if (error.response.status == 401) {
          AuthService.logout();
          window.location = "/login";
        }
        console.log("ERROR:: ", error.response.data);
        return null;
      });
  }

  countOpenAndClassified() {
    return axios
      .get(`${config["apiUrl"]}proposal/openAndClassified/count`, {
        headers: authHeader(),
      })
      .then((response) => {
        if (response.status == 200) {
          return response.data;
        }
        return null;
      })
      .catch((error) => {
        if (error.response.status == 401) {
          AuthService.logout();
          window.location = "/login";
        }
        console.log("ERROR:: ", error.response.data);
        return null;
      });
  }

  addNote(proposalId, $bodyParms) {
    return axios
      .post(`${config["apiUrl"]}proposal/${proposalId}/note`, $bodyParms, {
        headers: authHeader(),
      })
      .then((response) => {
        if (response.status != undefined && response.status == 200) {
          return response.data;
        }
        return null;
      })
      .catch((error) => {
        if (error.response.status == 401) {
          AuthService.logout();
          window.location = "/login";
        }
        console.log("ERROR:: ", error.response.data);
        return null;
      });
  }

  removeNote(proposalId, noteId) {
    return axios
      .delete(`${config["apiUrl"]}proposal/${proposalId}/note/${noteId}`, {
        headers: authHeader(),
      })
      .then((response) => {
        if (response.status != undefined && response.status == 200) {
          return response.data;
        }
        return null;
      })
      .catch((error) => {
        if (error.response.status == 401) {
          AuthService.logout();
          window.location = "/login";
        }
        console.log("ERROR:: ", error.response.data);
        return null;
      });
  }

  getBasicInformation(proposalId) {
    return axios
      .get(`${config["apiUrl"]}proposal/${proposalId}/basicInformation`, {
        headers: authHeader(),
      })
      .then((response) => {
        if (response.status == 200) {
          return response.data;
        }
        return null;
      })
      .catch((error) => {
        if (error.response.status == 401) {
          AuthService.logout();
          window.location = "/login";
        }
        console.log("ERROR:: ", error.response.data);
        return null;
      });
  }

  search(term) {
    return axios
      .get(`${config["apiUrl"]}proposal/search/${term}`, {
        headers: authHeader(),
      })
      .then((response) => {
        if (response.status == 200) {
          return response.data;
        }
        return null;
      })
      .catch((error) => {
        if (error.response.status == 401) {
          AuthService.logout();
          window.location = "/login";
        }
        console.log("ERROR:: ", error.response.data);
        return null;
      });
  }

  importProposals(proposalId, $bodyParms) {
    return axios
      .post(
        `${config["apiUrl"]}proposal/${proposalId}/import`,
        $bodyParms,
        {
          headers: authHeader(),
        }
      )
      .then((response) => {
        if (response.status != undefined && response.status == 200) {
          return response.data;
        }
        return null;
      })
      .catch((error) => {
        if (error.response.status == 401) {
          AuthService.logout();
          window.location = "/login";
        }
        console.log("ERROR:: ", error.response.data);
        return null;
      });
  }

  addToCRM(proposalId) {
    return axios
      .post(
        `${config["apiUrl"]}proposal/${proposalId}/toCrm`,
        {},
        {
          headers: authHeader(),
        }
      )
      .then((response) => {
        if (response.status != undefined && response.status == 200) {
          return response.data;
        }
        return null;
      })
      .catch((error) => {
        if (error.response.status == 401) {
          AuthService.logout();
          window.location = "/login";
        }
        console.log("ERROR:: ", error.response.data);
        return null;
      });
  }
}
export default new Proposal();
